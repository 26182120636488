const InstallProvider = ({detectComplete}) => {
  console.log(`InstallProvider detectComplete: ${detectComplete}  `);

  if (!detectComplete) {
    return <>
      <p className="lead mb-4">Looking for wallet...</p>
    </>
  } else {
    return <>
      <p className="lead mb-4">事前に
        <a className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ja">Metamask
        </a>
        ウォレットをGoogle Chromeブラウザにインストールして。
      </p>
      <p className="lead mb-4">Install <a
        className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
        target="_blank"
        href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en">Metamask</a> or
        other wallet to continue.
      </p>
    </>
  }
}

export default InstallProvider;
