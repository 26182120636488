import {ethers} from "ethers";
import {useEffect, useState} from "react";
import InstallProvider from "./InstallProvider";
import {initBindProviderEvents, mkHappyContract, mkHappyContractWithSigner} from "./ConnectWallet";
import {StandardMerkleTree} from "@openzeppelin/merkle-tree";
import HappyMainTreeHash from "./contracts/HappyMainTreeHash.json";
import SelectMint from "./SelectMint";
import Main from "./Main";
import happyMainAddress from "./contracts/contract-main-address.json";
import happyPolyAddress from "./contracts/contract-poly-address.json";
import HappyMainArtifact from "./contracts/HappyMain.json";
import HappyPolyArtifact from "./contracts/HappyPolygon.json";
import Poly from "./Poly";
import {POLYGON_PROVIDER} from "./constants";

const HappyMain = () => {
  const [mintOnMainnet, setMintOnMainnet] = useState(null);

  const [provider, setProvider] = useState(window.ethereum);
  const [polygonProvider] = useState(new ethers.WebSocketProvider(POLYGON_PROVIDER));

  // false for first 3sec or window.provider != undefined
  const [providerDetectComplete, setProviderDetectComplete] = useState(false);

  const [happyMainTree] = useState(StandardMerkleTree.load(HappyMainTreeHash))

  // wallet and chain
  const [initComplete, setInitComplete] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [networkVersion, setNetworkVersion] = useState(null); // int

  // contract and feedback
  const [happyContract, setHappyContract] = useState(null);

  // contract state
  const [happyBalanceOf, setHappyBalanceOf] = useState(null);
  const [happyName, setHappyName] = useState(null);
  const [happyPrice, setHappyPrice] = useState(null);
  const [happyMinted, setHappyMinted] = useState(null);
  const [happyTotalSupply, setHappyTotalSupply] = useState(null);
  const [happyAllowMint, setHappyAllowMint] = useState(null);
  const [happyBaseURI, setHappyBaseURI] = useState(null);

  const canMint = () => {
    return happyContract != null
      && happyAllowMint != null
      && happyAllowMint == true
      && happyPrice != null
      && happyBalanceOf != null
      && happyBalanceOf == 0n
      && happyMinted != null
      && happyTotalSupply != null
      && (happyMinted + 1n) < happyTotalSupply;
  }

  // listen for provider network and wallet address changes
  // ---
  useEffect(() => {
    const handleEthereum = () => {
      console.log(`handleEthereum providerDetectComplete:${providerDetectComplete}`);
      if (!providerDetectComplete) {
        if (window.ethereum) {
          setProvider(window.ethereum);
          initBindProviderEvents(window.ethereum, setNetworkVersion, setWalletAddress, setInitComplete);
        }
        setProviderDetectComplete(true);
      }
    }
    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
      });
      setTimeout(handleEthereum, 3000);
    }
  }, []);

  // update contract when signer or chain changes
  // ---
  useEffect(() => {
    (walletAddress === null || mintOnMainnet === null
        ? Promise.resolve(null)
        : mintOnMainnet
          ? mkHappyContractWithSigner(provider, happyMainAddress.happyMain, HappyMainArtifact)
          : mkHappyContract(polygonProvider, happyPolyAddress.happyPolygon, HappyPolyArtifact)
    ).then(contract => {
      console.log(`useEffect wallet: ${walletAddress} contract: ${contract}`);
      setHappyContract(contract);
    })
      .catch((error) => {
        console.log(error);
      })
  }, [walletAddress, mintOnMainnet]);

  // init and track contract state on contract change
  // ---
  useEffect(() => {
    if (happyContract != null) {
      // name
      happyContract.name()
        .then(name => setHappyName(name))
        .catch((error) => console.log(error));
      // price
      happyContract.on("SetPrice", (_, priceAfter) => {
        setHappyPrice(priceAfter);
      });
      happyContract.price()
        .then(price => setHappyPrice(price))
        .catch((error) => console.log(error));
      // minted
      const getMinted = () => happyContract.minted()
        .then(minted => setHappyMinted(minted))
        .catch((error) => console.log(error));
      const getBalanceOf = () => happyContract.balanceOf(walletAddress)
        .then(balanceOf => setHappyBalanceOf(balanceOf))
        .catch((error) => console.log(error));
      // Transfer(from, to, tokenId)
      happyContract.on("Transfer", (from, to, tokenId) => {
        if (from == ethers.ZeroAddress) {
          getMinted();
          getBalanceOf();
        }
      });
      getMinted();
      getBalanceOf();
      // totalSupply
      happyContract.on("SetTotalSupply", (supplyBefore, supplyAfter) => {
        setHappyTotalSupply(supplyAfter);
      });
      happyContract.totalSupply()
        .then(totalSupply => setHappyTotalSupply(totalSupply))
        .catch((error) => console.log(error));
      // allowMint
      happyContract.on("SetAllowMint", (allowBefore, allowAfter) => {
        setHappyAllowMint(allowAfter);
      });
      happyContract.allowMint()
        .then(allowMint => setHappyAllowMint(allowMint))
        .catch((error) => console.log(error));
      // baseURI
      happyContract.on("SetBaseURI", (baseBefore, baseAfter) => {
        setHappyBaseURI(baseAfter);
      });
      happyContract.baseURI()
        .then(baseURI => setHappyBaseURI(baseURI))
        .catch((error) => console.log(error));

    } else {
      setHappyBalanceOf(null);
      setHappyPrice(null);
      setHappyMinted(null);
      setHappyTotalSupply(null);
      setHappyAllowMint(null);
      setHappyBaseURI(null);
    }
  }, [happyContract, walletAddress]);
  // ---

  if (mintOnMainnet === null) {
    return <SelectMint setMintOnMainnet={setMintOnMainnet}/>;
  } else {
    return <div className="px-4 py-5 my-5 text-center">
      <div className="col-lg-6 mx-auto">
        {(provider === undefined || provider == null)
          ? <InstallProvider detectComplete={providerDetectComplete}/>
          : !initComplete
            ? <></>
            : mintOnMainnet
              ? <Main networkVersion={networkVersion}
                      walletAddress={walletAddress}
                      happyContract={happyContract}
                      happyName={happyName}
                      happyAllowMint={happyAllowMint}
                      happyPrice={happyPrice}
                      canMint={canMint()}
                      ownHappy={happyBalanceOf != null && happyBalanceOf > 0}/>
              : <Poly walletAddress={walletAddress}
                      happyContract={happyContract}
                      happyName={happyName}
                      happyAllowMint={happyAllowMint}
                      happyPrice={happyPrice}
                      canMint={canMint()}
                      ownHappy={happyBalanceOf != null && happyBalanceOf > 0}/>}
      </div>
    </div>;
  }
}

export default HappyMain;
