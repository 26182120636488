const SelectMint = ({setMintOnMainnet}) => {

  return <div className="container col-xxl-8 px-4">
    <div className="row g-5 py-5">
      <div className="col-lg-12 text-center">
        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-1">HAPPYNOLOGY FREE NFT MINT</h1>
        <p className="lead">"Happy + Technology = Happynology! This is an NFT gifted to attendees at Happynology V in
          Tokyo on 23.08.26. It might be useful later. Enjoy the art by Genec8."</p>
      </div>
    </div>
    <div className="row ">
      <div className="col-lg-6">
        <p className="lead mb-4">
          NFTについて初めての方には、Polygon上でNFTを作成することをおすすめします。ガス料金は一切必要ありません。NFTはウォレットにエアドロップされます。UNITED
          NEKO ALLIANCE FCFS（先着順） AL2にも登録されます。
        </p>
        <p className="lead mb-4">
          NFTに精通している方には、Ethereum上で作成することをおすすめします。UNITED NEKO
          ALLIANCEの確約されたミントAL1に、あなたと友達を登録できます。
        </p>
      </div>
      <div className="col-lg-6">
        <p className="lead mb-4">
          If you are new to NFTs, we recommend you mint your NFT on Polygon. You will not need to pay any gas. The NFT
          will be airdropped to your wallet and you will also be placed on the UNITED NEKO ALLIANCE FCFS AL2.
        </p>
        <p className="lead mb-4">
          If you are experienced with NFTs, we recommend minting on Ethereum. As an added benefit, you can get yourself
          and a friend on the UNITED NEKO ALLIANCE guaranteed mint AL1.
        </p>
      </div>
      <div className="col-lg-6">
        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
          <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" onClick={evt => {
            evt.preventDefault();
            setMintOnMainnet(true);
          }}>Ethereum
          </button>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
          <button type="button" className="btn btn-secondary btn-lg px-4" onClick={evt => {
            evt.preventDefault();
            setMintOnMainnet(false);
          }}>Polygon
          </button>
        </div>
      </div>
    </div>
  </div>;
}

export default SelectMint;
