import {codeCompact, codeHash, getProof, MINT_SERVER_URL, msgCodeInvalid, msgError} from "./constants";
import {useState} from "react";
import axios from "axios";
import {ethers} from "ethers";

/*
 Mint on polygon via server.
 */
const MintPoly = ({tree, code, walletAddress, setMessage, setJustMinted, canMint}) => {
  const [showSpinny, setShowSpinny] = useState(false);

  const doMint = () => {
    if (!canMint) {
      return
    }

    const compact = codeCompact(code.value);
    let hashed = codeHash(compact);
    const proof = getProof(tree, hashed);

    if (proof === null) {
      setMessage(msgCodeInvalid); // shouldn't happen
    } else {
      setShowSpinny(true);
      setMessage("");
      axios({
        method: 'POST',
        url: MINT_SERVER_URL,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          proof: proof.map(p => ethers.hexlify(p)),
          code: compact,
          recipient: walletAddress
        }
      })
        .then(r => {
          if (r.status != 200) {
            setMessage(msgError);
          } else {
            // tx shold be mined or error, reply is {success: bool, reason: string}
            setMessage(r.data.success ? "" : r.data.reason);
            if (r.data.success) {
              setJustMinted(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
          setMessage(err.reason ?? err.message ?? msgError);
        })
        .finally(() => {
          setShowSpinny(false);
        });
    }
  }

  return <form>
    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
      <button type="submit" className={"btn btn-primary  btn-lg px-4 gap-3" + (canMint && !showSpinny ? "" : " disabled")}
              onClick={evt => {
                evt.preventDefault();
                doMint();
              }}>
        <span role="status">Mint </span>
        {canMint && showSpinny && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
      </button>
    </div>
  </form>

}

export default MintPoly;
