import {OS_POLY_PREFIX} from "./constants";
import ConnectWallet from "./ConnectWallet";
import {useState} from "react";
import {StandardMerkleTree} from "@openzeppelin/merkle-tree";
import HappyMainTreeHash from "./contracts/HappyMainTreeHash.json";
import CheckCode from "./CheckCode";
import MintPoly from "./MintPoly";
import MintClosed from "./MintClosed";

/*
   show check code input and button and result
   if !wallet address
     show connect wallet button
    else
      if !owned
       show mint button (via server)
      else
       disabled mint button and OS link

 */
const Poly = ({walletAddress, happyContract, happyName, happyAllowMint, canMint, ownHappy}) => {
  const [happyTree] = useState(StandardMerkleTree.load(HappyMainTreeHash))
  // feedback
  const [message, setMessage] = useState("");
  // minting code
  const [code, setCode] = useState({value: "", valid: null});
  const [justMinted, setJustMinted] = useState(false);

  if (!walletAddress)
    return <ConnectWallet walletAddress={walletAddress}/>
  else
    return <>
      {(happyAllowMint && !(ownHappy || justMinted)) && <CheckCode happyContract={happyContract}
                                               tree={happyTree}
                                               code={code}
                                               setCode={(c, v) => setCode({value: c, valid: v})}
                                               setMessage={setMessage}/>}
      <p className="lead mb-4">{message || <>&nbsp;</>}</p>
      <>
        {happyAllowMint === false
          ? <MintClosed contractName={happyName}/>
          : <>
            <ConnectWallet walletAddress={walletAddress}/>
            {(ownHappy || justMinted)
              ? <>
                <p className="lead mb-4">
                  OpenSeaで発行されたNFTを閲覧できます。エアドロップですのでOpenSeaの非表示フォルダーに入ります。そこで表示設定を変更出来ます。
                </p>
                <p className="lead mb-4">
                  Click here to view your NFT on OpenSea. Because we airdropped your NFT to you, it will appear in your
                  hidden folder on OpenSea. Go there and you can unhide it.
                </p>
                <p className="lead mb-4">
                  <a href={OS_POLY_PREFIX + walletAddress} target="_blank"
                     className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">View
                    on OpenSea</a>
                </p>
              </>
              : walletAddress && <>
              <p className="lead mb-4">
                ボタンをクリックするとNFTが発行されます。Gas代はこちらで負担します。
              </p>
              <p className="lead">
                Click the button to mint your NFTs. We pay the gas fee.
              </p>
              <MintPoly tree={happyTree}
                        code={code}
                        walletAddress={walletAddress}
                        setMessage={setMessage}
                        setJustMinted={setJustMinted}
                        canMint={canMint && code.valid}/>
            </>}
          </>}
      </>
    </>;
}

export default Poly;
