import {HAPPYMAIN_NETWORK_ID, HAPPYMAIN_NETWORK_VERSION, OS_MAIN_PREFIX} from "./constants";
import SwapNetwork from "./SwapNetwork";
import ConnectWallet from "./ConnectWallet";
import {useState} from "react";
import {StandardMerkleTree} from "@openzeppelin/merkle-tree";
import HappyMainTreeHash from "./contracts/HappyMainTreeHash.json";
import CheckCode from "./CheckCode";
import MintMain from "./MintMain";
import MintClosed from "./MintClosed";

/*
  if wrong network
   show change network button (only)
  else
   show check code input and button and result
   if !wallet address
     show connect wallet button
    else
      if !owned
       show mint button
      else
       disabled mint button and OS link

 */
const Main = ({networkVersion, walletAddress, happyName, happyContract, happyAllowMint, happyPrice, canMint, ownHappy}) => {
  const [happyTree] = useState(StandardMerkleTree.load(HappyMainTreeHash))
  // feedback
  const [message, setMessage] = useState("");
  // minting code
  const [code, setCode] = useState({value: "", valid: null});

  if (networkVersion !== HAPPYMAIN_NETWORK_VERSION)
    return <SwapNetwork networkId={HAPPYMAIN_NETWORK_ID}/>
  else if (!walletAddress)
    return <ConnectWallet walletAddress={walletAddress}/>
  else
    return <>
      {(happyAllowMint && !ownHappy) && <CheckCode happyContract={happyContract}
                               tree={happyTree}
                               code={code}
                               setCode={(c, v) => setCode({value: c, valid: v})}
                               setMessage={setMessage}/>}
      <p className="lead mb-4">{message || <>&nbsp;</>}</p>
      <>
        {happyAllowMint === false
          ? <MintClosed contractName={happyName}/>
          : <>
            <ConnectWallet walletAddress={walletAddress}/>
            {ownHappy
              ? <>
                <p className="lead mb-4">
                  OpenSeaで発行されたNFTを閲覧できます。UNITED NEKO ALLIANCE FCFS
                  （先着順）AL2リストに登録されました。そして、確定ミントAL1リストにも登録することができます。今回HAPPYNOLOGYイベントに参加出来なかった友人にNFT1個を送り、そのことについてツイートしてください。ツイートには、あなたがNFTを送った相手と@NekoAllianceをメンションし、ハッシュタグ
                  #happynology を使用する必要があります。確認後、あなたと友達の両方をAL1リストに追加いたします。
                </p>
                <p className="lead mb-4">
                  Click here to view your NFT on OpenSea. You are automatically on the UNITED NEKO ALLIANCE FCFS AL2
                  list,
                  but you can also get on the guaranteed mint AL1 list. Share the love by sending one of your NFTs to a
                  friend and tweet about it. The tweet should mention the Twitter ID of who you sent your tweet to as
                  well
                  as mentioning @NekoAlliance and using the #happynology hashtag. We will confirm and add both you and
                  your friend to the AL1 list.
                </p>
                <p className="lead mb-4">
                  <a href={OS_MAIN_PREFIX + walletAddress} target="_blank"
                     className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">View
                    on OpenSea</a>
                </p>
              </>
              : walletAddress && <>
              <p className="lead mb-4">
                ボタンをクリックするとNFT2個が発行されます。Gas代がかかります。なぜ2個？ミント後説明します。
              </p>
              <p className="lead mb-4">
                Click the button to mint your NFTs. You will need enough Ethereum to pay for gas. You will receive two
                identical NFTs. Why 2 NFTs? We’ll tell you as soon as they are minted.
              </p>
              <MintMain happyMainContract={happyContract}
                        tree={happyTree}
                        code={code}
                        price={happyPrice}
                        setMessage={setMessage}
                        canMint={canMint && code.valid}/>
            </>}
          </>
        }
      </>
    </>
      ;
}

export default Main;
