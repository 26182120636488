import {ethers} from "ethers";

export const mkHappyContract = async (provider, address, artifact) => {
  return Promise.resolve(
    new ethers.Contract(
      address,
      artifact.abi,
      provider
    )
);
}
export const mkHappyContractWithSigner = async (provider, address, artifact) => {
  // We first initialize ethers by creating a provider using window.ethereum
  const web3 = new ethers.BrowserProvider(provider);

  return web3.getSigner().then(signer => {
    return new ethers.Contract(
      address,
      artifact.abi,
      signer
    );
  });
}

export const initBindProviderEvents = (provider, setNetworkVersion, setWalletAddress, setInitComplete) => {
  provider.request({method: 'eth_accounts'})
    .then((accounts) => {
      console.log(`eth_accounts accounts:${accounts}`);
      setWalletAddress(accounts.length > 0 ? accounts[0] : null); // can be undefined
      console.log(`provider.networkVersion: ${provider.networkVersion}`);

      // do here as was null when above
      console.log(`provider.networkVersion: ${provider.networkVersion}`);
      setNetworkVersion(provider.networkVersion);

      setInitComplete(true)
    }).catch((error) => {
    console.log(error);
  });

  provider.on("accountsChanged", (accounts) => {
    // this._stopPollingData(); update balance
    // `accountsChanged` event can be triggered with an undefined newAddress.
    // This happens when the user removes the Dapp from the "Connected
    // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
    // To avoid errors, we reset the dapp state
    // if (newAddress === undefined) {
    //   return this._resetState();
    // }

    console.log(`accountsChanged accounts:${accounts}`);
    setWalletAddress(accounts.length > 0 ? accounts[0] : null); // can be undefined
  });

  provider.on("chainChanged", (networkId) => {
    console.log(`chainChanged networkId:${networkId}`);
    setNetworkVersion((+networkId).toString());
  });
}

const connectWallet = () => {
  window.ethereum.request({method: 'eth_requestAccounts'})
    .then((accounts) => {
      // listener will update walletAddress
      console.log(accounts);
    })
    .catch((error) => {
      console.log(error);
    });
}

/**
 * Show connect button or address.
 * @returns {JSX.Element}
 * @constructor
 */
const ConnectWallet = ({walletAddress}) => {

  return (walletAddress === null)
    ? <>
      <p className="lead mb-4">”Connect Wallet”ボタンをクリックしてください。</p>
      <p className="lead mb-4">Click the ”Connect Wallet” button.</p>
      <div className="d-grid gap-2 d-md-flex justify-content-md-center">
        <button type="button" onClick={() => connectWallet()} className="btn btn-primary btn-lg px-4 gap-3">Connect
          Wallet
        </button>
      </div>
    </>
    : <p className="lead mb-4">
      <span className="font-monospace text-success">{walletAddress}</span>
    </p>;
}

export default ConnectWallet;
