const providerSwitchNetwork = (networkId) => {
  // https://stackoverflow.com/questions/68252365/how-to-trigger-change-blockchain-network-request-on-metamask
  window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{chainId: networkId}], // chainId must be in hexadecimal numbers
  })
    .catch(error => {
      console.log(error);
    });
}

const SwapNetwork = ({networkId}) => {
  return (
    <>
      <p className="lead mb-4">Wrong network.</p>
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button className="btn btn-lg btn-warning px-4 gap-3" onClick={() => providerSwitchNetwork(networkId)}>Switch
          Wallet to Mainnet
        </button>
      </div>
    </>
  )
}

export default SwapNetwork;
