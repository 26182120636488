import {codeCompact, codeHash, getProof, msgCodeInvalid, msgCodeValid, msgError} from "./constants";
import {useState} from "react";

const CheckCode = ({happyContract, tree, code, setCode, setMessage}) => {
  const [showSpinny, setShowSpinny] = useState(false);

  const checkCode = () => {
    if (happyContract === null) {
      return
    }

    const compact = codeCompact(code.value);
    let hashed = codeHash(compact);
    const proof = getProof(tree, hashed);

    if (proof === null) {
      setCode(code.value, false);
      setMessage(msgCodeInvalid);
    } else {
      setShowSpinny(true);
      happyContract.verify(proof, compact)
        .then(valid => {
          console.log(`code: ${code.value}, compact: ${compact}, hashed: ${hashed}, valid: ${valid}`);
          setCode(code.value, valid);
          setMessage(valid ? msgCodeValid : msgCodeInvalid);
        })
        .catch(err => {
          console.log(err);
          setCode(code.value, false);
          setMessage(err.reason??err.message??msgError);
        })
        .finally(_ => setShowSpinny(false));
    }
  };

  return <form>
    <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-4">
      <div className="col-6">
      <div className="input-group input-group-lg">
      <input type="text"
             placeholder="Enter Mint Code"
             className={"form-control" + (code.valid === null ? "" : (code.valid ? " is-valid" : " is-invalid"))}
             id="inputCode" value={code.value}
             onChange={evt => {
               setCode(evt.target.value, null);
               setMessage("");
             }}/>
    </div>
    </div>
    </div>
    <div className="d-grid gap-2 d-md-flex justify-content-md-center mb-4">
      <button type="submit" className={"btn btn-primary btn-lg px-4 gap-3" + (showSpinny ? " disabled" : "")} onClick={evt => {
        evt.preventDefault();
        checkCode();
      }}>
        <span role="status">Check Mint Code </span>
        {showSpinny && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
      </button>
    </div>
  </form>
}

export default CheckCode;
