import {codeCompact, codeHash, getProof, msgCodeInvalid, msgError} from "./constants";
import {useState} from "react";

const MintMain = ({happyMainContract, tree, code, price, setMessage, canMint}) => {
  const [showSpinny, setShowSpinny] = useState(false);

  const doMint = () => {
    if (happyMainContract === null || !canMint) {
      return
    }

    const compact = codeCompact(code.value);
    let hashed = codeHash(compact);
    const proof = getProof(tree, hashed);

    if (proof === null) {
      setMessage(msgCodeInvalid); // shouldn't happen
    } else {
      setShowSpinny(true);
      setMessage("");
      happyMainContract.mintWithCode(proof, compact, {value: price})
        .then(tx => tx.wait())
        .then(txRcpt => {
          //setCanMint(false); // NekoState event will update properly
          // setMessage(txRcpt.status)
          setMessage(txRcpt.status == 1 ? "" : msgError);
          setShowSpinny(txRcpt.status == 1); // keep spinny if tx success
        })
        .catch(err => {
          console.log(err);
          setMessage(err.reason??err.message??msgError);
          setShowSpinny(false);
        });
    }
  }

  return <form>
    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
      <button type="submit" className={"btn btn-primary btn-lg px-4 gap-3" + (canMint&&!showSpinny ? "" : " disabled")}
              onClick={evt => {
                evt.preventDefault();
                doMint();
              }}>
        <span role="status">Mint </span>
        {canMint && showSpinny && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
      </button>
    </div>
  </form>

}

export default MintMain;
