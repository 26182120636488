import {ethers} from "ethers";

export const MINT_SERVER_URL = "https://mint.happynology.com/api/mint";

export const POLYGON_PROVIDER = "wss://polygon-mainnet.g.alchemy.com/v2/oTnXVYbjbMBaiYMRKp1sW7jRVwWRSIV5";

export const OS_MAIN_PREFIX = "https://opensea.io/";
export const OS_POLY_PREFIX = "https://opensea.io/";

export const OS_SECONDARY_PREFIX = "https://opensea.io/collection/";

const MAINNET_NETWORK_ID = '0x1';
const MAINNET_NETWORK_VERSION = '1';

const SEPOLIA_NETWORK_ID = '0xaa36a7';
const SEPOLIA_NETWORK_VERSION = '11155111';

const HARDHAT_NETWORK_ID = '0x7a69'; // hex string, passed to wallet_switchEthereumChain
const HARDHAT_NETWORK_VERSION = '31337'; // int of id, returned from provider.networkVersion

// used to switch network for metamask main/sepolia mint
export const HAPPYMAIN_NETWORK_ID = MAINNET_NETWORK_ID;
export const HAPPYMAIN_NETWORK_VERSION = MAINNET_NETWORK_VERSION;

export const msgCodeValid = "Valid Code";
export const msgCodeInvalid = "Invalid Code";
export const msgError = "Error";

/*

    const valueIndex = typeof leaf === 'number' ? leaf : this.leafLookup(leaf);
    this.validateValue(valueIndex);

    // rebuild tree index and generate proof
    const { treeIndex } = this.values[valueIndex]!;
    const proof = getProof(this.tree, treeIndex);

    // sanity check proof
    if (!this._verify(this.tree[treeIndex]!, proof)) {
      throw new Error('Unable to prove value');
    }

    // return proof in hex format
    return proof.map(hex);



export function getProof(tree: Bytes[], index: number): Bytes[] {
  checkLeafNode(tree, index);

  const proof = [];
  while (index > 0) {
    proof.push(tree[siblingIndex(index)]!);
    index = parentIndex(index);
  }
  return proof;
}

 tree:
  StandardMerkleTree {tree: Array(11), values: Array(6), leafEncoding: Array(1), hashLookup: {…}}
    hashLookup:{0x57d057006e2548ed5e8fce7f5f74c6970815e7ad62adb60bcc7f83fa3f27db07: 0, 0xd55bdf78ee303e2741d89c1437719a7d27d35b94e309ba7a0b40cd0cfdedf132: 1,
    leafEncoding:['string']
    tree: (11) [Uint8Array(32), Uint8Array(32), Uint8Array(32), Uint8Array(32), Uint8Array(32), Uint8Array(32), Uint8Array(32),
 values: Array(6)
  0: treeIndex:5
    value:Array(1)
     0:"0x4eda21cf7b4b5f1f2aedb1516256de5f1e00c99bb889f15ae1d96aaf7a84681d"

 */

const throwError = msg => {
  throw new Error(msg);
}

const parentIndex = i => i > 0 ? Math.floor((i - 1) / 2) : throwError('Root has no parent');
const siblingIndex = i => i > 0 ? i - (-1) ** (i % 2) : throwError('Root has no siblings');

export const getProof = (tree, address) => {
  for (const v of tree.values) {
    if (v.value[0].toLowerCase() === address.toLowerCase()) {
      const proof = [];
      let index = v.treeIndex;
      while (index > 0) {
        proof.push(tree.tree[siblingIndex(index)]);
        index = parentIndex(index);
      }

      return proof;
    }
  }
  return null;
}

/*
  Replace double byte A-Z and whitespace with ascii equivalent
  Remove all ascii white space
  Convert to lower case
 */
export const codeCompact = function (code) {
  return code
    .replace(/\u3000/g, "") // double byte space
    .replace(/[Ａ-Ｚａ-ｚ]/g, c => String.fromCharCode(c.charCodeAt(0) - 0xFEE0)) // double byte A-Za-z
    .replace(/\s/g, "") // ascii whitespace
    .toLowerCase(); // lower case
}

export const codeHash = function (code) {
  // return ethers.keccak256(ethers.toUtf8Bytes(code));
  return ethers.id(code);
}
