import {OS_SECONDARY_PREFIX} from "./constants";

const OSLink = ({contractName, text}) => {
  return <a href={OS_SECONDARY_PREFIX + contractName.toLowerCase().replaceAll(" ", "-")} target="_blank"
            className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">{text}</a>;
}

const MintClosed = ({contractName}) => {

  return <>
    <p className="lead mb-4">Mint Closed. You can purchase on secondary <OSLink contractName={contractName} text="here"/>.</p>
    <p className="lead mb-4">ミントは終了しました。 <OSLink contractName={contractName} text="こちら"/>のセカンダリ市場で購入可能です。</p>
  </>;
}

export default MintClosed;
